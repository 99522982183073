import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PartialTable from 'components/partials/stock-location/PartialTable';
import { search as searchSection } from 'services/skill-matric-services/smSectionService';
import { search as searchArea } from 'services/skill-matric-services/smAreaService';
import { getAllEvaUsers } from 'services/userServices';
import './../../../../assets/scss/stickyTable.scss';

const SkillMatrixTable = ({
  data,
  paging,
  total,
  nextPage,
  previousPage,
  gotoPage,
  onPageSizeChange
}) => {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sectionsRes = await searchSection({ isGetAll: true });
        const areasRes = await searchArea({ isGetAll: true });
        const usersRes = await getAllEvaUsers();

        let cls = [
          {
            accessor: 'trainee',
            Header: 'Trainee',
            Cell: rowData => {
              const { trainee } = rowData.row.original;
              const user = usersRes.find(i => i.userId == trainee);
              return user ? user.name : '';
            }
          }
        ];

        let cl = generateColumns(sectionsRes.items, areasRes.items);
        cls.push(...cl);
        setColumns(cls);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  const generateColumns = (sections, areas) => {
    return sections.map(i => {
      return {
        Header: i.name,
        columns: areas
          .filter(a => a.sectionId === i.id)
          .map(a => {
            return {
              // accessor: 'areaId' + a.id?.toString(),
              accessor: a.id?.toString(),
              Header: (
                <div
                  style={{
                    transform: 'rotate(-45deg)',
                    display: 'inline-block',
                    textAlign: 'center',
                    width: '80px',
                    maxWidth: '100px',
                    whiteSpace: 'wrap'
                  }}
                >
                  {a.name}
                </div>
              )
            };
          })
      };
    });
  };

  return (
    <div className="sticky-zone">
      <PartialTable
        style={{
          border: '1px solid black',
          background: 'aliceblue',
          color: 'black',
          fontWeight: 'bold',
          textAlign: 'center'
        }}
        option={{ isStickyHeader: true }}
        data={data}
        columns={columns}
        paging={paging}
        total={total}
        nextPage={nextPage}
        previousPage={previousPage}
        gotoPage={gotoPage}
        onPageSizeChange={onPageSizeChange}
        textAlign=""
      />
    </div>
  );
};

SkillMatrixTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  paging: PropTypes.object,
  total: PropTypes.number,
  nextPage: PropTypes.func,
  previousPage: PropTypes.func,
  gotoPage: PropTypes.func,
  onPageSizeChange: PropTypes.func
};

export default SkillMatrixTable;
