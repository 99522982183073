import { URL } from '../../helpers/urlHelper';
import {
  baseSearch,
  basePost,
  basePatch,
  baseGet
} from 'services/base-services/baseService';
import { getAllEvaUsers } from 'services/userServices';
const baseURL = `${URL}/sm-training-records`;
import axios from 'axios';
axios.defaults.withCredentials = true;

const search = async request => {
  return await baseSearch(baseURL, request);
};

const getNotCompletes = async request => {
  return await baseSearch(baseURL + '/get-incomplete-records', request);
};

const getNotCompleteUsers = async request => {
  return await baseSearch(baseURL + '/get-incomplete-users', request);
};

const searchUsers = async request => {
  let res = await getAllEvaUsers();
  if (request.ids) {
    res = res.filter(i => request.ids.map(x=>x.trainee).includes(i.userId));
  }
 
  const rs = {
    items: res,
    total: res.length
  };
  return rs;
};

const post = async request => {
  return await basePost(baseURL, request);
};

const get = async id => {
  return await baseGet(baseURL, id);
};

const patch = async (id, request) => {
  return await basePatch(baseURL, request, id);
};

export {
  search,
  post,
  patch,
  get,
  searchUsers,
  getNotCompletes,
  getNotCompleteUsers
};
